.app {
  text-align: center;
  position: relative;
  z-index: 2; /* Ensure .app content is above the background video */
  padding-bottom: 15vh; /* Start with a default padding */
  background-color: black;
  color: white;
}

.background-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* This prevents the video from being stretched */
  z-index: -10; /* Set background video behind other content */
}

/* Header Styling */

.header {
  position: absolute;
  top: 0;
  left: 0;
  height: 60px; /* Adjust based on the size of your logo */
  width: 100%;
  display: flex;
  align-items: center;
  background-color: transparent;
  z-index: -1; /* Higher than the background video but not covering other elements */
  padding-left: 10px; /* Space from the left edge */
  order: 2;
}

@media (max-width: 768px) {
 .header {
  display: flex;
  height: 60px; /* Adjust based on the size of your logo */
  width: 100%;
  align-items: center;
  background-color: transparent;
  z-index: -1; /* Higher than the background video but not covering other elements */
  padding-left: 10px; /* Space from the left edge */
  padding-top: 60px;
  order: 2;
}
}

.logo {
  height: 125px; /* Adjust the size of your logo */
  position: absolute; /* Position logo absolutely within the header */
  top: 5px; /* Adjust based on header height */
  left: 10px; /* Adjust for spacing from the left edge */
}

@media (max-width: 768px) {
 .logo {
  height: 125px; /* Adjust the size of your logo */
  position: center; /* Position logo absolutely within the header */
  padding-top: 60px;
  padding-bottom: 20px;
}
}

/* Footer Styling */
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  text-align: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer a {
  display: block; /* Each link on its own line on small screens */
}

@media (min-width: 769px) {
  .footer {
    flex-direction: row;
    justify-content: space-around;
  }

  .footer a {
    display: inline; /* Links inline on larger screens */
  }
}

.twitter-icon {
  width: 40px; /* Adjust size as needed */
  height: auto;
  margin-right: 20px; /* Space between icons and text */
}

.discord-icon {
  width: 200px; /* Adjust size as needed */
  height: auto;
  margin-right: 20px; /* Space between icons and text */
}

.tele-icon {
  width: 40px; /* Adjust size as needed */
  height: auto;
  margin-right: 20px; /* Space between icons and text */
}

.degen-icon {
  width: 80px; /* Adjust size as needed */
  height: auto;
  margin-right: 20px; /* Space between icons and text */
}

/* Global Font Size */
html {
  font-size: 16px;
}

@media (max-width: 768px) {
  html {
    font-size: 14px; /* Smaller font size on smaller screens */
  }
}

/* Navigation Styling */
.navigation {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: 768px) {
  .nav-wrapper {
  margin-top: 30px;
  padding-top: 30px;
  text-align: center;
}
}

.nav-wrapper {
  margin-top: 30px;
  text-align: center;
}

.nav-button {
  padding: 10px 20px;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #a3a3a3;
  text-decoration: none;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  flex: 1 0 80%; /* Full width buttons on small screens */
  margin-bottom: 10px; /* Add some space between buttons */
}

.main-content {
  padding-bottom: 80px;
}

@media (min-width: 769px) {
  .nav-button {
    flex: 0 0 auto; /* Auto width buttons on larger screens */
  }
}

.nav-button:hover {
  background-color: #525252;
  color: white;
}

.nav-button.active {
  background-color: #ea580c;
  border-color: #ea580c;
  color: white;
}

/* NFT Card Styling */

.mint-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: auto;
  justify-content: center;
}

.nft-card {
  flex-basis: calc(50% - 20px); /* 2 cards per row by default */
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 10px;
  text-align: center;
}

@media (max-width: 768px) {
  .nft-card {
    flex-basis: calc(100% - 20px); /* 1 card per row on small screens */
  }
}

.nft-card button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.nft-card button:hover {
  background-color: #45a049;
}

.nft-card video.nft-video {
    width: 100%;
    height: auto;
    border-radius: 5px;
    margin-bottom: 10px;
}

.nft-image {
    width: 100%;
    height: auto;
    max-width: 756px;
    max-height: 756px;
    border-radius: 5px;
    margin-bottom: 10px;
}

/* My NFT Display */

.dashboard {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: auto;
  justify-content: center;
}

.mynft-card {
  flex-basis: calc(50% - 20px); /* 2 cards per row by default */
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 10px;
  text-align: center;
}

@media (max-width: 768px) {
  .mynft-card {
    flex-basis: calc(100% - 20px); /* 1 card per row on small screens */
  }
}

.mynft-image {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
}

.mynft-details {
    text-align: left;
    margin-top: 10px;
}

.recipient-input {
  width: 80%; /* Full width of the container */
  padding: 8px 10px; /* Adjust padding for better appearance */
  margin: 10px 0; /* Add some margin around the input field */
  border: 1px solid #ccc; /* Light border for the input */
  border-radius: 4px; /* Slightly rounded corners */
  box-sizing: border-box; /* Include padding and border in width */
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .recipient-input {
    width: calc(80% - 20px); /* Adjust width to ensure it fits well */
  }
}

/* Dynamic Widget */

.top-container {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  padding-right: 10px;
  z-index: 1000;
  order: 1;
}

@media (max-width: 768px) {
  .top-container {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 100px;
    z-index: 1000;
  }
}